import React, { useState } from 'react'
import { WorkplaceWellness } from './components/wellness-components'
import { LatLng } from 'leaflet'
import { useQuery } from '@apollo/client'
import { BuildingDetails } from './components/WellnessComponents/BuildingDetails/BuildingDetailsTypes'
import { PodDetails } from './components/WellnessComponents/PodDetails/PodDetailsTypes'
import {
  WellnessDisplaysQuery,
  WellnessDisplaysQueryVariables,
  WellnessDisplaysDocument,
  WellnessAddressesQuery,
  WellnessAddressesQueryVariables,
  WellnessAddressesDocument
} from './graphql/__generated__/types'
import useDeepEffect from 'use-deep-compare-effect'

//If you are in working on local development and there are no wellness ready pods uncomment this  
const fakeBuildings = [
  {
    buildingName: "Coors Field" ,
    buildingNickname: undefined,
    trafficScore: 'Low',
    percentageBooked: 23,
    wellnessScore: (0.5483 + 0.111)/2,
    address_full: "2001 Blake St, Denver, CO 80205" ,
    id: 1 ,
    totalDisplays: 2
  } as BuildingDetails, 
  {
    buildingName: "Colorado State Capitol" ,
    buildingNickname: undefined,
    trafficScore: 'High',
    percentageBooked: 63,
    wellnessScore: (0.97 + 0.3365)/2,
    address_full: "200 E Colfax Ave, Denver, CO 80203" ,
    id: 2 ,
    totalDisplays: 2
  } as BuildingDetails
]

const fakePodData = [ 
        {
          id: "Coors_Field_1",
          podName: "Rockies Dugout" ,
          availailtiy: 'booked',
          trafficScore: 'Moderate',
          unoccupiedTime: '1 hr', 
          address_id: 1 ,
          lat: 39.756166,
          lng: -104.995253,
          floor: 1,
          booked: '1:30:43',
          wellnessScore: 0.5483
        } as PodDetails ,
        {
          id: "Coors_Field_2",
          podName: "Dinger's Confernce Room" ,
          availailtiy: "all day",
          trafficScore: "Low",
          unoccupiedTime: "3 hr", 
          address_id: 1 ,
          lat: 39.755793,
          lng: -104.993645,
          floor: 1,
          booked: '2',
          wellnessScore: 0.123
        } as PodDetails,
        {
          id: "Cap_1",
          podName: "Colorado General Assembly" ,
          availailtiy: "booked",
          trafficScore: "High",
          unoccupiedTime: "0 hr", 
          address_id: 2 ,
          lat:   39.739312,
          lng: -104.984910 ,
          floor: 1,
          booked: '2',
          wellnessScore: 0.97
        } as PodDetails,
        {
          id: "Cap_2",
          podName: "Colorado Governor's Office" ,
          availailtiy: "all day",
          trafficScore: "Low",
          unoccupiedTime: "3 hr", 
          address_id: 2 ,
          lat: 39.739009,
          lng: -104.984698,
          floor: 2,
          booked: '2',
          wellnessScore: 0.3365
        } as PodDetails  
]
const  fakeData= false

function WellnessApp() {
  const [allPods, setAllPods]=useState<PodDetails[] | undefined>(undefined)
  const [allBuildings, setALLBuildings]=useState<BuildingDetails[] | undefined>(undefined)
  // //If you are in working on local development and there are no wellness ready pods uncomment this  
  
    const { data: DataPods , loading: loadingPods } = useQuery<WellnessDisplaysQuery,WellnessDisplaysQueryVariables
      >(WellnessDisplaysDocument, {fetchPolicy: 'cache-and-network', pollInterval: 10000})

    useDeepEffect(()=> {
      if(DataPods?.wellnessDisplays){
        const list = DataPods?.wellnessDisplays.map(el => (
        {
          id: el?.id,
          podName: el?.name ,
          availailtiy: 'all day',
          trafficScore: 'Moderate',
          unoccupiedTime: '30+ mins', 
          address_id: el?.address_id ,
          lat: el?.lat,
          lng: el?.lng,
          floor: el?.floor,
          booked: el?.booked,
          wellnessScore: el?.wellnessScore
        } as PodDetails  ))

        

        setAllPods( list )

      }  
    },[DataPods || []])

    const { data: DataBuildings, loading: loadingBuildings } = useQuery< WellnessAddressesQuery,WellnessAddressesQueryVariables
        >(WellnessAddressesDocument, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000
    })

    useDeepEffect(()=>{
      if(DataBuildings?.wellnessAddresses){
      const list = DataBuildings?.wellnessAddresses.map(el => (
        {
          buildingName: el?.building ,
          buildingNickname: el?.nickname ?? undefined,
          trafficScore: 'Low',
          percentageBooked: el?.percent_booked,
          wellnessScore: el?.wellnessScore,
          address_full: el?.address_full ,
          id: el?.id,
          totalDisplays: el?.num_of_displays
        } as BuildingDetails  ))

        

        setALLBuildings(list)
      }  
    },[DataBuildings || []])
  
    const urlParams = new URLSearchParams(window.location.search)
    const lat = urlParams.get('lat')
    const long = urlParams.get('long')
    const userLocation = (lat && long) ? new LatLng(parseFloat(lat), parseFloat(long)) : undefined

  return (
    <div >
      {!loadingPods && !loadingBuildings &&
        <WorkplaceWellness allPods={fakeData? fakePodData : allPods} allBuildings={fakeData? fakeBuildings : allBuildings} isAdmin={false} userLoc={userLocation} />
      }
    </div>
  )
}

export default WellnessApp
